import qs from "query-string";
import fetch from "isomorphic-fetch";
import DOMPurify from "dompurify";

export default function setupBookmarkletForm() {
  const secondsElm = document.getElementById("seconds");
  if (secondsElm) {
    initializeTimer(secondsElm);
    return;
  }

  const form = document.getElementById("new_user_episode");
  if (!form) return;
  if (!location.href.match(/\/bookmarklet/)) return;

  const titleField = form.querySelector("#user_episode_title");
  const descriptionField = form.querySelector("#user_episode_description");
  const fileUrlField = form.querySelector("#user_episode_file_url");
  const originalUrlField = form.querySelector("#user_episode_original_url");

  titleField.setAttribute("disabled", 1);
  descriptionField.setAttribute("disabled", 1);

  const params = qs.parse(location.search);

  if (params.url) {
    originalUrlField.value = params.url;

    getDetails(params.url).then((data) => {
      titleField.value = data.title;
      // descriptionField.value = data.content;
      descriptionField.value = DOMPurify.sanitize(data.content, {
        ALLOWED_TAGS: [
          "p",
          "a",
          "strong",
          "em",
          "ul",
          "ol",
          "li",
          "h1",
          "h2",
          "h3",
          "h4",
          "h5",
          "h6",
          "blockquote",
          "pre",
          "code",
          "figure",
          "figcaption",
        ],
      });

      titleField.removeAttribute("disabled");
      descriptionField.removeAttribute("disabled");
    });
  }

  if (params.feed === "true") {
    const notice = addNotice("Fetching feed");

    getFeed(params.file_url).then((episodes) => {
      notice.remove();

      const list = createListWithEpisodes(episodes, (episode) => {
        fileUrlField.value = episode.url;
        document.querySelector(".collapsible").querySelector("legend").click();
      });

      insertBeforeFieldset(list);
    });
  } else if (params.soundcloud === "true") {
    const notice = addNotice("Fetching info");

    getSoundcloudDetails(params.url).then((data) => {
      notice.remove();

      titleField.value = `${data.title} by ${data.user.username}`;
      descriptionField.value = data.description;

      if (data.downloadable) {
        fileUrlField.value = data.download_url;
      } else {
        alert("Not available for download :(");
      }

      document.querySelector(".collapsible").querySelector("legend").click();
    });
  } else {
    fileUrlField.value = params.file_url;
  }
}

function getDetails(url) {
  return getJson(`/readability/parse?url=${encodeURIComponent(url)}`);
}

function getFeed(url) {
  return getJson(`/feed/parse?url=${encodeURIComponent(url)}`);
}

function getJson(url) {
  return fetch(url, {
    headers: { Accept: "application/json" },
    credentials: "same-origin",
  }).then((resp) => resp.json());
}

function getSoundcloudDetails(url) {
  return getJson(`/soundcloud/expand?url=${encodeURIComponent(url)}`);
}

function createListWithEpisodes(episodes, onClick) {
  const list = document.createElement("ul");
  list.classList.add("list", "pl0");

  episodes.forEach((episode) => {
    const link = document.createElement("a");
    link.setAttribute("href", episode.url);
    link.innerHTML = episode.title;
    link.classList.add("link", "purpish");

    link.onclick = (event) => {
      event.preventDefault();
      onClick(episode);
      list.remove();
    };

    const li = document.createElement("li");
    li.classList.add("mb1");
    li.appendChild(link);

    list.appendChild(li);
  });

  return list;
}

function addNotice(text) {
  const notice = document.createElement("div");
  notice.className = "ba bw1 b--yellow dashed br3 b tc mv3 pa3";
  notice.innerHTML = text;

  insertBeforeFieldset(notice);

  return notice;
}

function insertBeforeFieldset(elm) {
  return document
    .querySelector("fieldset")
    .insertAdjacentElement("beforeBegin", elm);
}

function initializeTimer(secondsElm) {
  const tick = () => {
    const duration = parseInt(secondsElm.innerText, 10);
    console.log(duration);
    if (duration === 0) {
      window.close();
    } else {
      secondsElm.innerText = duration - 1;
      setTimeout(tick, 1000);
    }
  };

  tick();
}
